import { graphql, Link } from 'gatsby'
import * as React from 'react'

import { Layout } from '../components'

interface IndexProps {
  data: GatsbyTypes.TeamQuery
}

const Team: React.FC<IndexProps> = ({ data }) => {
  const members = data.allMembersJson.nodes

  return (
    <Layout>
      <h1>Meet the Team</h1>
      <ul>
        {members.map(member => (
          <li key={member.fields?.slug}>
            <Link to={`/members/${member.fields?.slug}`}>{member.name}</Link>
          </li>
        ))}
      </ul>
    </Layout>
  )
}

export default Team

export const query = graphql`
  query Team {
    allMembersJson {
      nodes {
        name
        fields {
          slug
        }
      }
    }
  }
`
